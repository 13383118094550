import React from 'react'

const ProductIcon = ({icon, color}) => {

    if (icon === 'hms') {
        return (
            <svg width="32" height="32" viewBox="0 0 32 32">
                <path className={`fill-current ${color}`}
                      d="M13.984,8.172,9.664.971A2,2,0,0,0,7.949,0H1A1,1,0,0,0,.183,1.574l6.954,9.935A12.935,12.935,0,0,1,13.984,8.172ZM31,0H24.051a2,2,0,0,0-1.715.971l-4.321,7.2a12.939,12.939,0,0,1,6.848,3.336l6.954-9.934A1,1,0,0,0,31,0ZM16,10A11,11,0,1,0,27,21,11,11,0,0,0,16,10Zm5.783,9.829-2.371,2.31.561,3.264a.718.718,0,0,1-1.041.756L16,24.618l-2.931,1.541a.717.717,0,0,1-1.041-.756l.561-3.264-2.371-2.31a.718.718,0,0,1,.4-1.224l3.277-.478,1.464-2.97a.719.719,0,0,1,1.288,0l1.464,2.97,3.277.478A.718.718,0,0,1,21.783,19.829Z"
                      transform="translate(0)"/>
            </svg>
        );
    }

    if (icon === 'office365') {
        return (
            <svg width="26.756" height="32" viewBox="0 0 26.756 32">
                <path className={`fill-current ${color}`}
                      d="M-1045.243-930.455h0l-17.183-6.149,17.29,2.248v-22.52l-11.153,2.574v15.121l-6.159,2.57-.009,0v-19.2l17.213-6.645,9.543,3.214v25.707l-9.543,3.08Z"
                      transform="translate(1062.456 962.455)"/>
            </svg>
        )
    }

    if (icon === 'dev') {
        return (
            <svg width="32" height="32.041" viewBox="0 0 32 32.041">
                <path className={`fill-current ${color}`}
                      d="M94.073,76.721H92.8a1.95,1.95,0,0,1-1.9-1.952,1.826,1.826,0,0,1,.626-1.376l.818-.8a2.049,2.049,0,0,0,0-2.911l-1.86-1.844a2.117,2.117,0,0,0-2.92,0l-.784.784a1.884,1.884,0,0,1-1.418.642A1.951,1.951,0,0,1,83.4,67.37V66.094A2.085,2.085,0,0,0,81.36,64H78.824a2.073,2.073,0,0,0-2.035,2.094v1.268a1.951,1.951,0,0,1-1.96,1.894,1.865,1.865,0,0,1-1.385-.617l-.809-.8a2.055,2.055,0,0,0-1.46-.584,2.106,2.106,0,0,0-1.46.584l-1.877,1.835a2.054,2.054,0,0,0,0,2.9l.784.784a1.889,1.889,0,0,1,.651,1.41,1.945,1.945,0,0,1-1.9,1.952H66.1A2.067,2.067,0,0,0,64,78.749v2.536a2.073,2.073,0,0,0,2.1,2.027H67.37a1.95,1.95,0,0,1,1.9,1.952,1.888,1.888,0,0,1-.651,1.41l-.784.776a2.054,2.054,0,0,0,0,2.9L69.7,92.2a2.055,2.055,0,0,0,1.46.584,2.106,2.106,0,0,0,1.46-.584l.809-.8a1.851,1.851,0,0,1,1.385-.617,1.951,1.951,0,0,1,1.96,1.894v1.268a2.079,2.079,0,0,0,2.044,2.094h2.536a2.073,2.073,0,0,0,2.035-2.094V92.68a1.938,1.938,0,0,1,3.378-1.251l.784.784a2.117,2.117,0,0,0,2.92,0l1.86-1.852a2.064,2.064,0,0,0,0-2.911l-.818-.8a1.839,1.839,0,0,1-.626-1.376,1.945,1.945,0,0,1,1.9-1.952h1.268A1.94,1.94,0,0,0,96,81.293V78.749A1.916,1.916,0,0,0,94.073,76.721Zm-7.316,3.3h0a6.674,6.674,0,1,1-13.347,0h0a6.674,6.674,0,0,1,13.347,0Z"
                      transform="translate(-64 -64)"/>
            </svg>
        )
    }

    if (icon === 'integration') {
        return (
            <svg width="26.839" height="32" viewBox="0 0 26.839 32">
                <path className={`fill-current ${color}`}
                      d="M28.226,18.435a3.072,3.072,0,0,1,2.031.807c1.133.685,1.581-.07,1.581-.807V14.823a3.106,3.106,0,0,0-3.1-3.1H23.065c-.735,0-1.495-.446-.807-1.581a3.068,3.068,0,0,0,.807-2.031c0-2-2.079-3.613-4.645-3.613s-4.645,1.617-4.645,3.613a3.1,3.1,0,0,0,.526,1.7c1.047,1.389.281,1.914-.526,1.914H8.1a3.106,3.106,0,0,0-3.1,3.1v3.613c0,.807.524,1.573,1.916.5a3.259,3.259,0,0,1,1.7-.5c1.994,0,3.613,2.081,3.613,4.645s-1.619,4.645-3.613,4.645a3.072,3.072,0,0,1-2.031-.807C5.448,26.233,5,26.989,5,27.726V33.4a3.106,3.106,0,0,0,3.1,3.1h5.677c.807,0,1.573-.524.5-1.914a3.289,3.289,0,0,1-.5-1.7c0-2,2.079-3.613,4.645-3.613s4.645,1.617,4.645,3.613a3.068,3.068,0,0,1-.807,2.031c-.687,1.135.072,1.581.807,1.581h5.677a3.106,3.106,0,0,0,3.1-3.1V27.726c0-.807-.524-1.573-1.916-.5a3.259,3.259,0,0,1-1.7.5c-1.994,0-3.613-2.081-3.613-4.645S26.231,18.435,28.226,18.435Z"
                      transform="translate(-5 -4.5)"/>
            </svg>
        )
    }

    if (icon === 'reporting') {
        return (
            <svg width="28" height="24" viewBox="0 0 28 24">
                <path className={`fill-current ${color}`}
                      d="M27.45,9.715H14.6L11.77,5.585a.733.733,0,0,0-.611-.335H4.032A1.884,1.884,0,0,0,2.25,7.2V26.18a2.944,2.944,0,0,0,2.8,3.07h22.4a2.944,2.944,0,0,0,2.8-3.07v-13.4A2.944,2.944,0,0,0,27.45,9.715ZM3.777,7.2a.268.268,0,0,1,.255-.279h6.751l1.9,2.791H3.777ZM21.86,16.446l-6.608,6.7a.705.705,0,0,1-1.018,0L10.64,19.483a.908.908,0,0,1,0-1.183.717.717,0,0,1,1.079,0l3.055,3.114,6.109-6.173a.717.717,0,0,1,1.079,0,.9.9,0,0,1,.18.626A.875.875,0,0,1,21.86,16.446Z"
                      transform="translate(-2.25 -5.25)"/>
            </svg>
        )
    }

    if (icon === 'crm') {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                <path className={`fill-current ${color}`}
                      d="M33.292,9.461,31.138,6.848A2.367,2.367,0,0,0,29.333,6H10.667a2.338,2.338,0,0,0-1.8.848L6.716,9.461A3.082,3.082,0,0,0,6,11.444V30.889A3.11,3.11,0,0,0,9.111,34H30.889A3.11,3.11,0,0,0,34,30.889V11.444A3.119,3.119,0,0,0,33.292,9.461ZM20,28.556,11.444,20h5.444V16.889h6.222V20h5.444ZM9.306,9.111l1.268-1.556H29.24l1.454,1.556Z"
                      transform="translate(-6 -6)"/>
            </svg>
        )
    }
    return (
        <span className={`${icon} ${color}`}></span>
    );
}

export default ProductIcon;