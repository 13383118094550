import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import DecalOne from "../assets/decal1.svg";
import DecalTwo from "../assets/decal2.svg";
import {graphql, Link, StaticQuery} from "gatsby";
import Container from "./container";
import GradientImage from "./gradient-image";
import ScrollDownButton from './scroll-down-button'

const carouselQuery = graphql`
query CarouselQuery {
    carousel: allSanityCarousel(
        sort: {fields: [order], order: DESC}
    ) {
        edges {
            node {
                title
                text
                order
                action {
                    url
                    name
                }
                image {
                    ...Image
                }
                mobileImage: image {
                    ...MobileImage
                }
            }
        }
    }
}
`

class FrontpageCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentIndex: 0,
            items: []
        };
    }

    slideTo = (i) => this.setState({currentIndex: i});

    onSlideChanged = (e) => this.setState({currentIndex: e.item});

    slideNext = (items) => {
        let nextIndex = this.state.currentIndex + 1;
        if (nextIndex > (items.length - 1)) {
            nextIndex = 0;
        }
        this.setState({currentIndex: nextIndex});
    }

    slidePrev = (items) => {
        let prevIndex = this.state.currentIndex - 1;
        if (prevIndex < 0) {
            prevIndex = items.length - 1;
        }
        this.setState({currentIndex: prevIndex})
    };

    renderThumbs = () =>
        <ul>{items.map((item, i) =>
            <li key={i} onClick={() => this.slideTo(i)}>Thumb {i}</li>)}
        </ul>;

    renderGallery(items) {
        const {currentIndex} = this.state;
        return (<AliceCarousel
            dotsDisabled={true}
            fadeOutAnimation={true}
            autoPlay={true}
            infinite={true}
            autoPlayInterval={10000}
            buttonsDisabled={true}
            slideToIndex={currentIndex}
            onSlideChanged={this.onSlideChanged}
        >
            {items.map(({node}) => (
                <GradientImage key={`carousel-image-${node.__id}`}
                               image={node.image.asset ? node.image.asset.fluid : null}
                               mobileImage={node.mobileImage.asset ? node.mobileImage.asset.fluid : null}/>
            ))}
        </AliceCarousel>);
    }

    render() {

        return (
            <StaticQuery
                query={carouselQuery}
                render={({carousel}) => {
                    let items = carousel.edges;

                    return (
                        <div className='bg-brand-blue md:bg-transparent relative' id='front-page-carousel'>
                            <div className="relative z-20 max-w-xs md:max-w-sm">
                                <img src={DecalOne} className='absolute hidden md:block'/>
                                <img src={DecalTwo}
                                     className='absolute top-0 mix-blend-mode md:mt-200 w-8 mt-6 md:w-auto'/>
                            </div>
                            <div className='relative z-10'>{this.renderGallery(items)}</div>
                            <div className="relative md:static">
                                <div
                                    className='hidden md:block absolute z-50 inset-0 flex items-end pointer-events-none pb-40  -mt-40'>
                                    <Container>
                                        <div className='flex justify-between '>
                                            <button onClick={() => this.slidePrev(items)}
                                                    className='pointer-events-auto text-white text-5xl w-16 h-16 flex items-center justify-center rounded-full bg-white bg-opacity-25 hover:bg-brand-blue transition duration-75 ease'>
                                                <span
                                                    className="block border-b-2 border-l-2 border-white h-8 w-8 transform rotate-45 -mr-3"/>
                                            </button>
                                            <button onClick={() => this.slideNext(items)}
                                                    className='pointer-events-auto text-white text-5xl w-16 h-16 flex items-center justify-center rounded-full bg-white bg-opacity-25 hover:bg-brand-blue transition duration-75 ease'>
                                                <span
                                                    className="block border-b-2 border-r-2 border-white h-8 w-8 transform -rotate-45 -ml-3"/>
                                            </button>
                                        </div>
                                    </Container>
                                </div>
                                <div
                                    className='absolute bottom-0 inset-x-0 mb-20 flex items-center justify-center pointer-events-auto z-40'>
                                    {items.map((node, index) => (
                                        <button key={'slideNav' + index} onClick={() => this.slideTo(index)}
                                                className={`${this.state.currentIndex === index ? 'bg-white' : 'bg-brand-grey-darker'} rounded-full w-dot h-dot mx-dot`}>

                                        </button>
                                    ))}
                                </div>
                            </div>

                            <div
                                className='md:bg-transparent -mt-12 md:mt-0 relative md:absolute z-50 inset-0 flex items-end justify-center md:pb-40'>
                                <Container>
                                    <div className='md:px-130'>
                                        <h2 className='text-2xl text-center md:text-left md:text-5xl text-white mb-6 font-semibold leading-tight font-heading max-w-3xl tracking-tighter'>
                                            {items[this.state.currentIndex].node.title}
                                        </h2>
                                        <div
                                            className='text-base text-center md:text-left text-white mb-16 max-w-xl leading-normal'>
                                            {items[this.state.currentIndex].node.text}
                                        </div>
                                        <div
                                            className='text-base md:text-lg text-white leading-loose text-center md:text-left md:pl-8'>
                                            <Link to={items[this.state.currentIndex].node.action.url}
                                                  className={`inline-flex text-base leading-tight font-bold text-white h-16 items-center bg-brand-red-light hover:bg-brand-red px-8 py-5 rounded-full font-heading uppercase tracking-wide`}>
                                                {items[this.state.currentIndex].node.action.name}
                                            </Link>
                                        </div>
                                    </div>
                                </Container>

                            </div>
                            <div className='flex md:hidden items-center justify-center transform translate-y-61px'>
                                <ScrollDownButton
                                    parent='#front-page-carousel'/>
                            </div>
                        </div>
                    )
                }
                }/>
        );
    }
}

export default FrontpageCarousel;
