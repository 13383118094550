import React from 'react';

const ScrollDownButton = (props) => {
    const scrollDown = () => {
        let distance = 1000;
        if (typeof window !== 'undefined') {
            distance = 650 - window.scrollY;
        }
        if (props.parent && typeof document !== 'undefined') {
            let parent = document.querySelector(props.parent)
            distance = parent.offsetHeight - window.scrollY;
        }
        window.scrollBy({
            top: distance,
            left: 0,
            behavior: 'smooth'
        });
    }

    return (
        <button
            onClick={scrollDown}
            className={(props.className || '') + 'z-30 text-white text-5xl w-122 h-122 transform hover:scale-105 red-hover flex items-center justify-center rounded-full bg-brand-red-light transition duration-300 ease'}>
                <span
                    className="block border-b-2 border-r-2 border-white h-10 w-10 transform rotate-45 -mt-5"/>
        </button>
    );
}

export default ScrollDownButton;