import React from 'react'
import {graphql} from 'gatsby'
import {getPostUrl} from '../lib/helpers'
import Container from '../components/container'
import SEO from '../components/seo'
import Layout from '../components/layout'
import GraphQLErrorList from '../components/graphql-error-list'
import Card from '../components/card'
import PortableText from '../components/portableText'
import FrontpageCarousel from '../components/frontpage-carousel'
import FrontpageProducts from "../components/frontpage-products";

export const query = graphql`
query IndexPageQuery {
    posts: allSanityPost(
        limit: 3
        sort: { fields: [publishedAt], order: DESC }
        filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
        edges {
            node {
                id
                publishedAt
                mainImage {
                    ...MainImage
                }
                mainImageSquare: mainImage {
                    ...SquareMainImage
                }
                title
                _rawExcerpt
                slug {
                    current
                }
            }
        }
    }
}
`

class IndexPage extends React.Component {
    constructor(props) {
        super(props);
        const {data, errors} = props
        this.state = {
            errors: errors,
            carousel: (data || {}).carousel || [],
            posts: (data || {}).posts,
            products: (data || {}).products
        }
    }

    render() {

        if (this.state.errors) {
            return (
                <GraphQLErrorList errors={this.state.errors}/>
            )
        }

        return (
            <>
                <SEO/>

                <FrontpageCarousel items={this.state.carousel}/>
                <FrontpageProducts items={this.state.products}/>

                <Container className='pt-24 md:pt-10 md:pb-24'>
                    <h3 className='font-heading font-semibold text-3xl md:text-4xl mb-12 md:px-20 text-brand-red relative flex items-center tracking-tight'>
                        <span className=" z-10 rounded-full bg-white h-20 w-20"></span>
                        <span className="-ml-10 z-20">Siste fra bloggen</span>
                    </h3>
                    <div className='flex flex-col md:flex-row -mx-6 md:-mx-4 mb-8'>
                        {this.state.posts && this.state.posts.edges.map(({node}, index) => (
                            <div className="md:px-4 w-full md:w-1/3" key={`post-${index}`}>
                                <Card
                                    url={getPostUrl(node.publishedAt, node.slug)}
                                    title={node.title}
                                    text={(<PortableText blocks={node._rawExcerpt}/>)}
                                    image={node.mainImage.asset.fluid}
                                    squareImage={node.mainImageSquare.asset.fluid}
                                    readMoreText='Les mer'
                                    date={node.publishedAt}
                                />
                            </div>
                        ))}
                    </div>
                </Container>
            </>
        )
    }
}

export default IndexPage
