import React from 'react'
import {Link} from 'gatsby'
import Img from 'gatsby-image'
import {format} from 'date-fns'

const Card = ({url, image, squareImage = null, title, text, readMoreText, date = null}) => {
    let mobileImage = image;
    if (squareImage) {
        mobileImage = squareImage;
    }
    return (
        <Link to={url}
              className='block h-full group no-underline md:hover:shadow-lg transition-all duration-500 ease-in-out md:bg-white'>
      <span className='flex h-full flex-col text-brand-body'>
        <span className='hidden md:block h-280 md:h-auto md:pb-1/2 relative mb-8'>
          <span className='absolute inset-0 overflow-hidden'>
            <Img fluid={image}
                 className='object-cover transition duration-500 ease-in-out transform group-hover:scale-105'/>
          </span>
        </span>
        <span className='md:hidden h-280 md:h-auto md:pb-1/2 relative mb-8'>
          <span className='absolute inset-0 overflow-hidden'>
            <Img fluid={mobileImage}
                 className='object-cover transition duration-500 ease-in-out transform group-hover:scale-105'/>
          </span>
        </span>
        <span className='flex flex-col px-10 md:px-8 mb-4 flex-1'>
          <h2 className='font-heading text-xl font-semibold leading-tight tracking-tight mb-7px transition-colors duration-500 ease-in-out group-hover:text-brand-red'>{title}</h2>
            {date && (
                <span className='block mb-3'>
                <span
                    className='text-byline font-medium text-brand-grey-darker'>Publisert {format(date, 'DD.MM.YYYY')}</span>
              </span>
            )}
            <span className='block text-base leading-snug mb-10 flex-1'>{text}</span>
        </span>
      </span>
        </Link>
    )
}

export default Card
