import React, {Component} from 'react'
import Container from "./container";
import {graphql, Link, StaticQuery} from "gatsby";
import {slideDown, slideUp} from "../lib/helpers";
import ProductIcon from "./icon/productIcon";

const frontPageProductsQuery = graphql`
query FrontPageProductsQuery {
    products: allSanityProduct(
        filter: {showOnFrontPage: {eq: true}}
        sort: {fields: [order], order: DESC}
    ) {
        edges {
            node {
                id
                title
                excerpt
                icon
                productIcon
                slug {
                    current
                }
            }
        }
    }
}
`

class FrontpageProducts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            shouldShow: false,
            currentItem: null
        }
    }

    splitTitle(name) {
        if (name.includes(':')) {
            let fragments = name.split(':')
            return {
                product: fragments[0],
                name: fragments[1]
            }
        }
        return {
            product: null,
            name
        }
    }

    activateItem(node) {
        clearTimeout(this.timeout)
        if (!this.state.currentItem) {
            setTimeout(() => {
                slideDown(document.querySelector('#product-info'), 300)
            }, 50)
        }
        this.setState({currentItem: node});
    }

    deactivateItem() {
        this.timeout = setTimeout(() => {
            slideUp(document.querySelector('#product-info'), 300)
            setTimeout(() => this.setState({currentItem: null}), 302)
        }, 500)
    }

    componentDidMount() {
        if (window.matchMedia("(min-width: 768px)").matches) {
            this.setState({shouldShow: true})
        }
        window.matchMedia("(min-width: 768px)").addListener((e) => {
            this.setState({shouldShow: e.matches})
        });
    }

    render() {
        if (!this.state.shouldShow) {
            return null;
        }

        return (

            <StaticQuery
                query={frontPageProductsQuery}
                render={({products}) => {
                    let items = products.edges;

                    return (
                        <Container className='mb-8'>
                            <div
                                className='flex items-center md:justify-center flex-wrap -mx-4 -mt-10 z-40 relative mb-8'>
                                {items.map(({node}, index) => (
                                    <div className='px-4'
                                         key={`product-${index}`}>
                                        <Link to={`/product/${node.slug.current}`}
                                              onMouseEnter={() => this.activateItem(node)}
                                              onMouseLeave={() => this.deactivateItem()}

                                              className='group flex flex-col items-center rounded-full h-48 w-48 bg-brand-grey-darker text-gray-200 hover:bg-brand-red-light hover:text-white transition-all duration-300 ease transform hover:scale-105'>
                                            <span className='mt-8 mb-2 h-6 w-6 relative mb-4'>
                                                <span
                                                    className='text-2xl group-hover:opacity-0 absolute inset-0 flex items-center justify-center'>
                                                    <ProductIcon icon={node.productIcon || node.icon}
                                                                 color='text-brand-grey-lighter'/>
                                                </span>
                                                <span
                                                    className='text-2xl opacity-0 group-hover:opacity-100 inset-0 flex items-center justify-center '>
                                                    <span
                                                        className={`fa fa-arrow-right`}/>
                                                </span>
                                            </span>
                                            <span className='text-center px-4 text-lg leading-tight'>
                                                {node.title}
                                            </span>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                            <div className='max-w-lg p-8 mx-auto text-brand-body h-48 text-center' id='product-info'
                                 style={{display: 'none'}}>
                                <h2 className='font-heading text-lg font-semibold mb-3'>{this.state.currentItem ? this.state.currentItem.title : ''}</h2>
                                <div className='text-base leading-normal'>
                                    {this.state.currentItem ? this.state.currentItem.excerpt : ''}
                                </div>
                            </div>
                        </Container>
                    )
                }}/>
        );
    }

}

export default FrontpageProducts;