import React from 'react'
import Img from "gatsby-image/index";

const GradientImage = ({image, mobileImage, className}) => (
    <div className={`bg-brand-blue ${className} md:pl-500`}>
        <div className='bg-brand-blue relative overflow-hidden  mx-auto sm:h-hero'>
            {mobileImage && (
                <Img fluid={mobileImage} className='lg:hidden md:object-cover h-full'/>
            )}
            <Img fluid={image} className='hidden lg:block md:object-cover h-full'/>
            <div
                className='hidden xl:block absolute right-0 top-0 bottom-0 -mb-10 main-hero-gradient-reverse w-2/3 lg:w-full'/>
            <div
                className='absolute left-0 top-0 bottom-0 -mb-10 main-hero-gradient w-full md:w-2/3 lg:w-full'/>
        </div>
    </div>
)

export default GradientImage;